




























































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {OutputLocationDTO, UpdateLocationDTO} from "@/api";
// import FmondsList from './FmondsList.vue';

@Component({
  components: {
    // FmondsList,
  },
})
export default class LocationsEdit extends Vue {
  @Prop(Number) readonly locationId;
  @Prop(Number) readonly organizationId;

  public valid = true;

  public location: OutputLocationDTO|null = null;

  public formData = {
    name: '',
    latlong: ''
  }
  public dialog: boolean = false;

  public async mounted() {
    this.location = await this.$store.dispatch('locations/get', { locationId: this.locationId })
    this.reset();
  }

  public reset() {
    this.$validator.reset();

    if (this.location) {
      this.formData.name = this.location.name;
      this.formData.latlong = '' + this.location.latitude + ', ' + this.location.longitude;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (this.location === null) {
      return;
    }

    if (await this.$validator.validateAll()) {

      const arr = this.formData.latlong.split(',').map(Number);
      if (arr.length !== 2 || isNaN(arr[0]) || isNaN(arr[1])) {
        this.$store.commit('ui/addErrorNotification', 'Invalid longitude/latitude format');
        return;
      }

      const dto: UpdateLocationDTO = {
        name: this.formData.name,
        latitude: arr[0],
        longitude: arr[1]
      }

      await this.$store.dispatch('locations/update', { locationId: this.locationId, data: dto })
      await this.$router.push({
        name: 'main-admin-organisations-edit',
        params: { organisationId: this.organizationId },
      });
    }
  }
}
